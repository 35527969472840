import React from 'react';
import '../styles/Resources.css';
import { NavLink } from 'react-router-dom';
import Footer from '../Footer/Footer.js';



const Resources = () => {
	
	 
	return(
		
	  <section className="Resources-main-container">
		
		
		
		<div className="Resources-promo-subcontainer">
		  <a href="https://www.sys.tm/signup" className="sign-in-nav" aria-label="Sign up now button"><p>Limited Time: FREE for 30 Days. Sign Up Now</p></a>
	    </div>
		<div className="Navbar-2025promo-subcontainer">
		  <a href="https://www.sys.tm/signup" className="promo-sign-in" id="signup-button" aria-label="Sign up button"><p>Save an Additional 25% on Annual Subscriptions. Offer Expires June 30, 2025</p></a>
	    </div>
		<div className="Navbar-epson-subcontainer">
		  <a href="https://www.scw.com/digitech/" className="promo-sign-in" id="signup-button" aria-label="Sign up button"><p>LIMITED TIME OFFER: Exclusive Epson Document Management Bundle</p></a>
	    </div>
		
		<section className="Resources-welcome-container">
		
		  <div className="Resources-main-left">
		    <h1>Resources</h1>
		    <p>Learn more about Sys.tm and how it can benefit your business by exploring any of the resources below.</p>
		  </div>
		  <div className="Resources-main-right">
		  </div>
		</section>
		
		<section className="Resources-main-article">
		
		
	 <section className="Resources-article-container">
		
		<div className="Resources-article-item">
		  <div className="Resources-sys"></div>
		   <div className="Resources-article-text">
			<h2>Digitech Systems Adds Generative AI to Sys.tm<sup>®</sup></h2>
		    <div className="Resources-article-maintag">
			  <div className="Resources-article-tagcontainer">Microservices & Microtransactions-based Billing</div>
			  <div className="Resources-article-tagcontainer">Sys.tm</div>
			  <div className="Resources-article-tagcontainer">Artificial Intelligence</div>
			  <div className="Resources-article-tagcontainer">BPM</div>
			  <div className="Resources-article-tagcontainer">Automation</div>
		    </div>
		    <h4>Making GenAI Easier to Use and Easier to Afford</h4>
			<p className="Resources-article-excerpt">Today, Digitech Systems announced the availability of AI Query within Sys.tm<sup>®</sup> Intelligence, a flexible GenAI tool that enhances the Sys.tm information management and automation platform.</p>
		   <NavLink to="/genai-announcement" className="welcome-button contact-button Resources-article-linkbutton">Learn More</NavLink>
		  </div>
		</div>
		
		<div className="Resources-article-item">
		  <div className="Resources-sys"></div>
		  <div className="Resources-article-text">
			<h2>Sys.tm<sup>®</sup> Product Sheet</h2>
		    <div className="Resources-article-maintag">
			  <div className="Resources-article-tagcontainer">Microservices & Microtransactions-based Billing</div>
			  <div className="Resources-article-tagcontainer">Sys.tm</div>
			  <div className="Resources-article-tagcontainer">Artificial intelligence</div>
			  <div className="Resources-article-tagcontainer">Automation</div>
		    </div>
		    <h4>Bring Information to Life</h4>
			<p className="Resources-article-excerpt">How much time and money would better information management save your business? Sys.tm provides all the power of the world’s best information management and task automation in an easy-to- setup, easy-to-use product that offers you the ability to customize available features and pay only for what you actually use.</p>
		   <NavLink to="/product-sheet" className="welcome-button contact-button Resources-article-linkbutton">Learn More</NavLink>
		 </div>
		</div>	
			
		<div className="Resources-article-item">
		  <div className="Resources-sys"></div>
		  <div className="Resources-article-text">
			<h2>Sys.tm Pricing Sheet</h2>
		    <div className="Resources-article-maintag">
			  <div className="Resources-article-tagcontainer">Microservices & Microtransactions-based Billing</div>
			  <div className="Resources-article-tagcontainer">Sys.tm</div>
			  <div className="Resources-article-tagcontainer">Artificial intelligence</div>
			  <div className="Resources-article-tagcontainer">Automation</div>
		    </div>
		    <h4>Choose The Best Plan For Your Organization</h4>
			<p className="Resources-article-excerpt">Download the Sys.tm pricing sheet to learn more about features included in Sys.tm Standard, Sys.tm Professional, and Sys.tm Automations (Robotic Process Automation), along with available add-ons and prebuilt integrations.</p>
		   <NavLink to="/pricing-sheet" className="welcome-button contact-button Resources-article-linkbutton">Learn More</NavLink>
		 </div>
		</div>
		
		<div className="Resources-article-item">
		  <div className="Resources-sys"></div>
		  <div className="Resources-article-text">
			<h2>Digitech Systems Adds BPM and AI to the Sys.tm<sup>®</sup> Platform</h2>
		    <div className="Resources-article-maintag">
			  <div className="Resources-article-tagcontainer">Microservices & Microtransactions-based Billing</div>
			  <div className="Resources-article-tagcontainer">Sys.tm</div>
			  <div className="Resources-article-tagcontainer">Artificial intelligence</div>
			  <div className="Resources-article-tagcontainer">Automation</div>
		    </div>
		    <h4>Making GenAI Easier to Use and Easier to Afford</h4>
			<p className="Resources-article-excerpt">Today, Digitech Systems announced the availability of Sys.tm<sup>®</sup> Flows and Sys.tm<sup>®</sup> Intelligence, a flexible business process automation (BPM) tool and an Artificial Intelligence (AI) driven recognition that enhance the Sys.tm information management platform.</p>
		   <NavLink to="/flows-announcement" className="welcome-button contact-button Resources-article-linkbutton">Learn More</NavLink>
		 </div>
		</div>
		
		
			 
	  <div className="Resources-article-item">
		  <div className="Resources-sys"></div>
		  <div className="Resources-article-text">
			<h2>The Modular Automation Revolution</h2>
		    <div className="Resources-article-maintag">
			  <div className="Resources-article-tagcontainer">Microservices & Microtransactions-based Billing</div>
			  <div className="Resources-article-tagcontainer">Sys.tm</div>
			  <div className="Resources-article-tagcontainer">Press Release</div>
			  <div className="Resources-article-tagcontainer">Robotic Process Automation</div>
		    </div>
		    <h4>How Microservices and RPA Are Redefining 2025 Business Strategies</h4>
			<p className="Resources-article-excerpt">As businesses adjust to rising technology expenses and unprecedented data growth, microservices architectures and Robotic Process Automation (RPA) are emerging as top priorities for those looking to maximize efficiency and savings.</p>
		   <NavLink to="/module-automation-revolution" className="welcome-button contact-button Resources-article-linkbutton">Learn More</NavLink>
		 </div>
		</div>
						
		<div className="Resources-article-item">
		  <div className="Resources-micro"></div>
		  <div className="Resources-article-text">
			<h2>3 Critical Technology Trends Shaping 2025</h2>
		    <div className="Resources-article-maintag">
			  <div className="Resources-article-tagcontainer">Microservices & Microtransactions-based Billing</div>
			  <div className="Resources-article-tagcontainer">Sys.tm</div>
			  <div className="Resources-article-tagcontainer">Press Release</div>
			  <div className="Resources-article-tagcontainer">Automation</div>
		    </div>
		    <h4>Generative AI, Automation, and Modular Technologies Shape Worker Experience</h4>
			<p className="Resources-article-excerpt">Microservices enable you to choose individual features that meet your specific needs. You can save money and increase your return on investment by purchasing technology that strikes a reasonable balance between price and performance.</p>
		   <NavLink to="/CriticalTrends2025" className="welcome-button contact-button Resources-article-linkbutton">Learn More</NavLink>
		 </div>
		</div>
			
		<div className="Resources-article-item">
		  <div className="Resources-sys"></div>
		  <div className="Resources-article-text">
			<h2>Digitech Systems Launches Next-Gen Information Management</h2>
		    <div className="Resources-article-maintag">
			  <div className="Resources-article-tagcontainer">Microservices & Microtransactions-based Billing</div>
			  <div className="Resources-article-tagcontainer">Sys.tm</div>
			  <div className="Resources-article-tagcontainer">Press Release</div>
			  <div className="Resources-article-tagcontainer">Automation</div>
		    </div>
		    <h4>A flexible information management platform for digital transformation and office automation.</h4>
			<p className="Resources-article-excerpt">Today, Digitech Systems announced the availability of Sys.tm, a flexible information management platform for digital transformation and office automation that allows users to effectively manage files, share documents, and secure data.</p>
		   <NavLink to="/digitech-systems-launches-next-gen-information-management" className="welcome-button contact-button Resources-article-linkbutton">Learn More</NavLink>
		 </div>
		</div>		
			
		<div className="Resources-article-item">
		  <div className="Resources-usb"></div>
		  <div className="Resources-article-text">
			<h2>Sys.tm Brings Intelligent Information Management to SMBs</h2>
		    <div className="Resources-article-maintag">
			  <div className="Resources-article-tagcontainer">Microservices & Microtransactions-based Billing</div>
			  <div className="Resources-article-tagcontainer">Sys.tm</div>
			  <div className="Resources-article-tagcontainer">Media Coverage</div>
			  <div className="Resources-article-tagcontainer">Robotic Process Automation</div>
		    </div>
		    <h4>An innovative platform that delivers enterprise-grade content management and process automation to businesses of any size</h4>
			<p className="Resources-article-excerpt">Many SMBs compromise on features or security when buying technology in order to save money. Are you among them? If so, our newest product, Sys.tm offers everything you need without forcing you to pay for premium tiers or licenses explains US Business News. Check it out.</p>
		   <NavLink to="https://usbusinessnews.com/sys-tm-brings-intelligent-information-management-to-smbs/" target="_blank" rel="noopener noreferrer" className="welcome-button contact-button Resources-article-linkbutton">Learn More</NavLink>
		 </div>
		</div>		
							
		<div className="Resources-article-item">
		  <div className="Resources-sys"></div>
		  <div className="Resources-article-text">
			<h2>Three Musts for a Successful Digital Transformation eBook</h2>
		    <div className="Resources-article-maintag">
			  <div className="Resources-article-tagcontainer">Microservices & Microtransactions-based Billing</div>
			  <div className="Resources-article-tagcontainer">Sys.tm</div>
			  <div className="Resources-article-tagcontainer">eBook</div>
			  <div className="Resources-article-tagcontainer">Robotic Process Automation</div>
		    </div>
		    <h4>Intelligent information management services play a crucial role in streamlining document and data management.</h4>
			<p className="Resources-article-excerpt">Download the Three Musts for a Successful Digital Transformation eBook for a helpful guide to your organization’s digital transformation journey.</p>
		   <NavLink to="/digital-transformation" className="welcome-button contact-button Resources-article-linkbutton">Learn More</NavLink>
		 </div>
		</div>	
	  
	  <div className="Resources-article-item">
		 <div className="Resources-ds"></div>
		  <div className="Resources-article-text">
			<h2>Digitech Systems announces the availability of Sys.tm</h2>
		    <div className="Resources-article-maintag">
			  <div className="Resources-article-tagcontainer">Microservices & Microtransactions-based Billing</div>
			  <div className="Resources-article-tagcontainer">Sys.tm</div>
			  <div className="Resources-article-tagcontainer">Media Coverage</div>
			  <div className="Resources-article-tagcontainer">Robotic Process Automation</div>
		    </div>
		    <h4>Feeling Stuck in Your Digital Transformation?</h4>
			<p className="Resources-article-excerpt">As the pace of business becomes more frantic, companies are working harder than ever to manage and secure their business information.</p>
		   <NavLink to="https://www.documentmedia.com/article-3520-Digitech-Systems-Launches-Next-Gen-Information-Management.html" className="welcome-button contact-button Resources-article-linkbutton">Learn More</NavLink>
		 </div>
		</div>

		<div className="Resources-article-item">
		 <div className="Resources-sys"></div>
		  <div className="Resources-article-text">
			<h2>Sys.tm Microservices Data Sheet</h2>
		    <div className="Resources-article-maintag">
			  <div className="Resources-article-tagcontainer">Microservices & Microtransactions-based Billing</div>
			  <div className="Resources-article-tagcontainer">Sys.tm</div>
			  <div className="Resources-article-tagcontainer">Data Sheet</div>
		    </div>
		    <h4>Ready to smart-size your technology spend?</h4>
			<p className="Resources-article-excerpt">Download the Microservices Data Sheet to learn how Digitech Systems microservices and microtransactions-based billing benefit your organization.</p>
		   <NavLink to="/microservices-data-sheet" className="welcome-button contact-button Resources-article-linkbutton">Learn More</NavLink>
		 </div>
		</div>
	  
	   <div className="Resources-article-item">
		 <div className="Resources-sys"></div>
		  <div className="Resources-article-text">
			<h2>Microservices and Microtransactions-based Billing Definitions</h2>
		    <div className="Resources-article-maintag">
			  <div className="Resources-article-tagcontainer">Microservices & Microtransactions-based Billing</div>
			  <div className="Resources-article-tagcontainer">Sys.tm</div>
		    </div>
		    <h4>Emerging trend in tech comes with evolving terminology</h4>
			<p className="Resources-article-excerpt">Gain a better understanding of the emerging technology trends of microservices and microtransactions-based billing with this list of terms and descriptions.</p>
		   <NavLink to="/microservices-definitions" className="welcome-button contact-button Resources-article-linkbutton">Learn More</NavLink>
		 </div>
		</div>
			
		<div className="Resources-article-item">
		 <div className="Resources-sys"></div>
		  <div className="Resources-article-text">
			<h2>What is a Microservices Architecture? Video</h2>
		    <div className="Resources-article-maintag">
			  <div className="Resources-article-tagcontainer">Microservices & Microtransactions-based Billing</div>
			  <div className="Resources-article-tagcontainer">Sys.tm</div>
			  <div className="Resources-article-tagcontainer">Video</div>
			  <div className="Resources-article-tagcontainer">Robotic Process Automation</div>
		    </div>
		    <h4>Stop wasting money on unused software features!</h4>
			<p className="Resources-article-excerpt">Microservices enable you to choose individual features that meet your specific needs. You can save money and increase your return on investment by purchasing technology that strikes a reasonable balance between price and performance.</p>
		   <NavLink to="/architecture-video" className="welcome-button contact-button Resources-article-linkbutton">Learn More</NavLink>
		 </div>
		</div>
			
		<div className="Resources-article-item">
		 <div className="Resources-epson"></div>
		  <div className="Resources-article-text">
			<h2>Southern Computer Warehouse Partners with Epson and Digitech Systems to Offer Exclusive Document Management Bundle for a Limited Time</h2>
		    <div className="Resources-article-maintag">
			  <div className="Resources-article-tagcontainer">Microservices & Microtransactions-based Billing</div>
			  <div className="Resources-article-tagcontainer">Sys.tm</div>
			  <div className="Resources-article-tagcontainer">Promo</div>
		    </div>
		    <h4>Solution Combines Epson DS Series Scanners with Sys.tm<sup>®</sup> from Digitech Systems for Enhanced Document Efficiency and Security</h4>
			<p className="Resources-article-excerpt">Southern Computer Warehouse (SCW), a leading value-added reseller of technology solutions, has announced an exclusive limited-time promotion in partnership with Epson and Digitech Systems.</p>
		   <NavLink to="/southern-computer" className="welcome-button contact-button Resources-article-linkbutton">Learn More</NavLink>
		 </div>
		</div>
			
			
	  
		
			
	  </section>

		  </section>
		
		<Footer/>
			
	  </section>
	)
	
}

export default Resources;