import React from 'react';
import '../styles/Posts.css';

import Footer from '../Footer/Footer.js';

const relatedArticles = [
	
	{
	   id: '2',
	   tags: ['Microservices & Microtransactions-based Billing', 'Sys.tm'],
	   title: 'Sys.tm Pricing Sheet',
	   extrenal_link: './#/pricing-sheet',
	   grabber_question: 'Choose The Best Plan For Your Organization',
	   excerpt: 'Download the Sys.tm pricing sheet to learn more about features included in Sys.tm Standard, Sys.tm Professional, and Sys.tm Automations (Robotic Process Automation), along with available add-ons and prebuilt integrations.',
       downloads: '',
	   feature_image: 'Resources-usb'
	},
	{
	   id: '3',
	   tags: ['Microservices & Microtransactions-based Billing', 'Sys.tm'],
	   title: 'Sys.tm Microservices Data Sheet',
       extrenal_link: './#/microservices-data-sheet',
	   grabber_question: 'Ready to smart-size your technology spend?',
	   excerpt: 'Download the Microservices Data Sheet to learn how Digitech Systems microservices and microtransactions-based billing benefit your organization.',
       downloads: '',
	   feature_image: 'Resources-sys'
	}
	
]



const ResourcesProductSheet = () => {
	 
	
	return(
		
	  <section className="Posts-main-container">
		
		<div className="Posts-promo-subcontainer">
			<a href="https://www.sys.tm/signup" className="sign-in-nav" aria-label="Sign up now button"><p>Limited Time: FREE for 30 Days. Sign Up Now</p></a>
	    </div>
		
		
		<div className="Posts-welcome-container">
			<h1>Sys.tm® Product Sheet</h1>
		</div>
		
	  <div className="Posts-body-container">
		
		  <h2>Sys.tm<sup>®</sup> Ends File Frustration and Digital Chaos</h2>
		  <p>Traditional file management locks information away in boxes or complex electronic structures, making it hard to access and difficult to secure. From the moment you turn it on, Sys.tm is different. You can ingest and organize your files automatically— no time-consuming uploading required. Those documents can then be used anywhere by anyone on any device without compromising security and easing collaboration both within your company and with other business partners. Sys.tm brings your business information to life.</p>
		<br/>
		<div className="Posts-intro-rowcontainer">
		  <p className="Posts-textside-container">By downloading this product sheet you’ll learn all about the features, capabilities, and business solutions Sys.tm has to offer which help organizations securely manage and access their information. This product sheet will show you how your business can speed up information retrieval time by storing data in the cloud while protecting your business documents with multiple levels of security.Even technical people will benefit from this product sheet as it showcases the product technical specifications as well. This product sheet is your one-stop-shop for all things Sys.tm.</p>
		   
		      <div className="Posts-systm-mobile"></div>
		   
		</div>	
		
		<div className="Posts-intro-veritcalcontainer">
			<a href="https://s3.us-east-1.amazonaws.com/www.info.sys.tm/uploads/Systm%20Product%20Sheet.pdf" target="_blank"><button className="Posts-button">Download Now</button></a>
			<p>Download this product sheet to learn more about how Sys.tm's flexible information management platform allows users to effectively manage files, share documents, and secure data.</p>
		</div>
<br/>
	  
	</div>
	
	<section className="Posts-related-section">
		<h3>Related Articles</h3>
		<div className="Posts-related-container">
			
		{relatedArticles.map((article) => {
		
			return (
			<a className="Posts-article-linkbutton" href={article.extrenal_link} target="_blank" rel="noopener noreferrer">
				<div className="Posts-related-item" href={article.extrenal_link} target="_blank" rel="noopener noreferrer">
				  
				  <div className="Posts-article-title">
					  <h2>{article.title}</h2>
				
				
				    <div className="Posts-article-textcontainer">
				  	  <p className="Posts-article-grebber"><b>{article.grabber_question}</b></p>
				      <p className="Posts-article-excerpt">{article.excerpt}</p>
					</div>
					
				  </div>
				</div>	
				</a>
			)
	})}
	  
	  </div>
		
	</section>
			
</section>
	)
	
}

export default ResourcesProductSheet;