import React from 'react';
import '../styles/Posts.css';

import Footer from '../Footer/Footer.js';

const relatedArticles = [
	
	{
	   id: '2',
	   tags: ['Microservices & Microtransactions-based Billing', 'Sys.tm'],
	   title: 'Sys.tm Brings Intelligent Information Management to SMBs',
	   extrenal_link: 'https://usbusinessnews.com/sys-tm-brings-intelligent-information-management-to-smbs/',
	   grabber_question: '',
	   excerpt: 'Many SMBs compromise on features or security when buying technology in order to save money. Are you among them? If so, our newest product, Sys.tm offers everything you need without forcing you to pay for premium tiers or licenses explains US Business News. Check it out.',
       downloads: '',
	   feature_image: 'Resources-usb'
	},
	{
	   id: '3',
	   tags: ['Microservices & Microtransactions-based Billing', 'Sys.tm'],
	   title: 'Three Musts for a Successful Digital Transformation eBook',
       extrenal_link: 'https://info.sys.tm/#/digital-transformation',
	   grabber_question: 'Oversized documents a big pain?',
	   excerpt: 'Download the Three Musts for a Successful Digital Transformation eBook for a helpful guide to your organization’s digital transformation journey.',
       downloads: '',
	   feature_image: 'Resources-sys'
	}
	
]



const FlowsAnnouncement = () => {
	
console.log(relatedArticles);	 
	
	return(
		
	  <section className="Posts-main-container">
		
		<div className="Posts-promo-subcontainer">
			<a href="https://www.sys.tm/signup" className="sign-in-nav" aria-label="Sign up now button"><p>Limited Time: FREE for 30 Days. Sign Up Now</p></a>
	    </div>
		
		<div className="Navbar-2025promo-subcontainer">
		  <a href="https://www.sys.tm/signup" className="promo-sign-in" id="signup-button" aria-label="Sign up button"><p>Save an Additional 25% on Annual Subscriptions. Offer Expires June 30, 2025</p></a>
	    </div>
		
		<div className="Posts-welcome-container">
			<h1>Digitech Systems Adds BPM and AI to the Sys.tm<sup>®</sup> Platform</h1>
		</div>
		
	  <div className="Posts-body-container">
		
		  <h2>Flexible Pricing Changes the Economics of Automation and Artificial Intelligence</h2>
		  <div className="Posts-intro-container">
			<p><b>Greenwood Village, CO – March 11, 2025 –</b> As customers and companies navigate an unprecedented rate of change, technologies need to flex rapidly to support these shifts.</p>
			<img src="https://www.digitechsystems.com/wp-content/uploads/2024/09/systm-logo.jpg" alt="" width="250" />
		</div>
		
		<p>Today, Digitech Systems announced the availability of Sys.tm<sup>®</sup> Flows and Sys.tm<sup>®</sup> Intelligence, a flexible business process automation (BPM) tool and an Artificial Intelligence (AI) driven recognition engine that enhance the Sys.tm information management platform. As the first composable automation platform available, Sys.tm Flows enables workers to customize automated processes and to simply and quickly make adjustments on-the-fly as their needs change. Sys.tm Intelligence leverages artificial intelligence to recognize documents and information, extract it, and securely send it to wherever it is needed. As components of Sys.tm, Flows and Intelligence also inherit the platform’s modular design and unique pricing to better suit shifting business priorities.</p>
<br/>
	<div className="Posts-plusimg-container">
		<p>A tool for digital transformation and office automation, Sys.tm allows users to effectively manage and secure files, share documents, and automate tasks and processes. The <a href="https://www.digitechsystems.com/features/cloud-information-management/">cloud-based service</a> innovates how businesses consume and pay for these foundational capabilities. Sys.tm’s <a href="https://www.digitechsystems.com/features/microservices-microtransactions/">microservices architecture</a> (also known as composable and flexible consumption), allows users to expand their feature set as needed, but they only pay for the capabilities they actually use. Sys.tm eliminates the expense of traditional software licenses or cloud services tiers where as much as 80% of the purchased features are rarely, if ever, used.<sup>1</sup></p>
		<div className="Posts-plusimg-pr"></div>
	</div>		
		
<br/>
			
<ul>
  <li><strong>Sys.tm Flows</strong> helps companies to automate virtually any Sys.tm capability including document routing, user activities, decision trees and more, saving money by enabling automation across the business.</li>
  <li><strong>Sys.tm Intelligence</strong> bundles sensible, results-oriented AI capabilities, such as recognition of handwriting and text (Optical Character Recognition/OCR), document classification, and automated invoice processing, into everyday business processes, streamlining information tasks and supporting significant competitive advantage. </li>
</ul>
	
<br/>
	  
<div className="Posts-plusimg2-container">
	<p>The average worker loses two hours daily to looking for information or shuttling documents through traditional office processes, and that goes up to three hours for management.<sup>2</sup>  That means businesses waste hundreds of hours per employee per year at a significant sunk cost. Sys.tm Flows and Sys.tm Intelligence change these dynamics by placing sound information management, smart AI assistance, and business automation within the familiar Windows file system simplifying every task and process organization-wide.</p>
		
	<div className="Posts-quote-box">
		<em><blockquote>
		<p>“The marketplace and customer needs change rapidly, so flexibility is paramount for today’s technology buyers,” says Digitech Systems CEO, HK Bain. “Automating tasks and processes is a critical competitive advantage, so automation and AI technologies need to be modular in nature, enabling companies to rapidly and simply adjust not only the processes themselves, but also to choose the features and pricing best suited to their current needs. Sys.tm’s secure information management coupled with AI-driven task and process automation bring business information to life in a flexible, composable architecture.”</p>	
		</blockquote></em>
	</div>	
	
</div>	
<br/>

	<br/>

<p>Visit <a href="https://www.digitechsystems.com/">DigitechSystems.com</a> to learn more about our innovative approach to <a href="https://www.digitechsystems.com/features/microservices-microtransactions/">technology design,</a> business <a href="https://www.digitechsystems.com/automation/">automation</a> and how <a href="https://www.sys.tm/welcome">Sys.tm</a> can help you better manage and secure your important business information.</p>

<h3>About Digitech Systems, LLC</h3>

<p>Digitech Systems is the most trusted choice for intelligent information management and business process automation. Preferred by thousands of businesses worldwide from global conglomerates to Main Street, teams count on Digitech Systems to pave the path to more meaningful work by transforming how they handle digital files, processes, documents and more.</p>
<br/>
<p>Celebrated by industry analysts and insiders for creating the best enterprise content management and workflow solutions on the market, Digitech Systems has an unsurpassed legacy of accelerating business performance by streamlining digital processes for organizations of any size with the Sys.tm<sup>®</sup>, PaperVision<sup>®</sup> and ImageSilo<sup>®</sup> brands. For decades, Digitech Systems’ technologies have kept their customers on the knowledge management leading edge. To learn more about Digitech Systems and their award-winning suite of information management, workflow, electronic forms, and robotic process automation technologies, visit <a href="www.digitechsystems.com">www.digitechsystems.com.</a></p>
	
	<a href="https://www.pendo.io/resources/the-2019-feature-adoption-report/"><p className="Info-backup"><sup>1</sup> https://www.pendo.io/resources/the-2019-feature-adoption-report/</p></a>
<a href="https://www.starmind.ai/hubfs/Assets%202022/Future%20of%20Work%20Report%20-%20The%20High%20Cost%20of%20Inaccessible%20Knowledge/Future%20of%20work_Research%20report.pdf"><p className="Info-backup"><sup>2</sup> https://www.starmind.ai/hubfs/Assets%202022/Future%20of%20Work%20Report%20-%20The%20High%20Cost%20of%20Inaccessible%20Knowledge/Future%20of%20work_Research%20report.pdf</p></a>
	
	</div>
	
	<section className="Posts-related-section">
		<h3>Related Articles</h3>
		<div className="Posts-related-container">
			
		{relatedArticles.map((article) => {
		
			return (
			<a className="Posts-article-linkbutton" href={article.extrenal_link} target="_blank" rel="noopener noreferrer">
				<div className="Posts-related-item" href={article.extrenal_link} target="_blank" rel="noopener noreferrer">
				  
				  <div className="Posts-article-title">
					  <h2>{article.title}</h2>
				
				
				    <div className="Posts-article-textcontainer">
				  	  <p className="Posts-article-grebber"><b>{article.grabber_question}</b></p>
				      <p className="Posts-article-excerpt">{article.excerpt}</p>
					</div>
					
				  </div>
				</div>	
				</a>
			)
	})}
	  
	  </div>
		
	</section>
			
</section>
	)
	
}

export default FlowsAnnouncement;