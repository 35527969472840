import React from 'react';
import '../Pricing/Pricing.css';
import Footer from '../Footer/Footer.js';

import { NavLink } from 'react-router-dom';

const emtest = () => {
	return(
	  <section className="Pricing-main-container">
		
		<h1>Em was Here</h1>
	
	  </section>
	)
}

export default emtest;