import React from 'react';
import '../Pricing/Pricing.css';
import Footer from '../Footer/Footer.js';

import { NavLink } from 'react-router-dom';

const Pricing = () => {
	return(
	  <section className="Pricing-main-container">
		
		<div className="Navbar-promo-subcontainer">
		<a href="https://www.sys.tm/signup" className="sign-in-nav" id="signup-button" aria-label="Sign up free button" ><p>Limited Time: FREE for 30 Days. Sign Up Now</p></a>
	    </div>
		
		<div className="Navbar-2025promo-subcontainer">
		  <a href="https://www.sys.tm/signup" className="sign-in-nav" id="signup-button" aria-label="Sign up button"><p>Save an Additional 25% on Annual Subscriptions. Offer Expires June 30, 2025.</p></a>
	    </div>
	
		<div className="Navbar-epson-subcontainer">
		  <a href="https://www.scw.com/digitech/" className="promo-sign-in" id="signup-button" aria-label="Sign up button"><p>LIMITED TIME OFFER: Exclusive Epson Document Management Bundle</p></a>
	    </div>
	
		<div className="pricing-headerbanner-container">
		
		<div className="pricing-welcome-text"> 
		   <h1>Choose The Best Plan For Your Organization</h1>
		   <p className="Pricing-main-subtitle">View the information below for pricing and features for Sys.tm Standard and Sys.tm Professional as well as Sys.tm<sup>®</sup> Automations, Sys.tm<sup>®</sup> Flows and Sys.tm<sup>®</sup> Intelligence. Annual discounts and volume discounts may be available.</p>
		</div>
		
		</div>
		
		<NavLink to="/genai-announcement" className="Learn-press-release" rel="noopener noreferrer">
		  <h3>Introducing Sys.tm: The perfect balance of intelligent information management, automation, AI, value and functionality.</h3>
		</NavLink>
		
	    <section className="Pricing-plans">

          <section className="Pricing-plans-subcontainer">
		
		   <section className="Pricing-plans-items No-bottom-margin">
		       <h3>Professional</h3>  
		    <div className="Pricing-plans-titles" >
			 <div className="Pricing-price-container">
	           <h4>$55/mo* per user</h4>
	  		   <p>when billed monthly</p>
		     </div>
			<h5>or</h5>
			 <div className="Pricing-price-container">
	           <h4><span className="Pricing-line-through">$49.50</span> $37.13/mo* per user</h4>
	  		   <p>when billed annually</p>
		     </div>
		    </div> 
			<section className="Pricing-feature-container">
		  		  <div className="Pricing-feature-itemcontainer top-feature">
		    <h5 className="Pricing-feature-itemtext">File Management</h5>
		    <div className="Pricing-feature-textcontainer">
				<img src="https://www.digitechsystems.com/wp-content/uploads/2024/01/check.png" width="18" height="18" alt="Check Icon"/>
		    </div>
		  </div>
		  <div className="Pricing-feature-itemcontainer">
		    <h5 className="Pricing-feature-itemtext">Data Security</h5>
		    <div className="Pricing-feature-textcontainer">
		      <img src="https://www.digitechsystems.com/wp-content/uploads/2024/01/check.png" width="18" height="18" alt="Check Icon"/>
		    </div>
		  </div>
		  <div className="Pricing-feature-itemcontainer">
		    <h5 className="Pricing-feature-itemtext">100 GB Storage (per user)**</h5>
			<div className="Pricing-feature-textcontainer">
		    <img src="https://www.digitechsystems.com/wp-content/uploads/2024/01/check.png" width="18" height="18" alt="Check Icon"/>
		    </div>
		  </div>
		  <div className="Pricing-feature-itemcontainer">
		    <h5 className="Pricing-feature-itemtext">Sys.tm Windows Client</h5>
		    <div className="Pricing-feature-textcontainer">
		      <img src="https://www.digitechsystems.com/wp-content/uploads/2024/01/check.png" width="18" height="18" alt="Check Icon"/>
			</div>
		  </div>
		  <div className="Pricing-feature-itemcontainer">
		    <h5 className="Pricing-feature-itemtext">Multi Factor Authentication (MFA)</h5>
		    <div className="Pricing-feature-textcontainer">
		    <img src="https://www.digitechsystems.com/wp-content/uploads/2024/01/check.png" width="18" height="18" alt="Check Icon"/>
			</div>
		  </div>
		  <div className="Pricing-feature-itemcontainer">
		    <h5 className="Pricing-feature-itemtext">Customizable Dashboard Widgets</h5>
	        <div className="Pricing-feature-textcontainer">
		    <img src="https://www.digitechsystems.com/wp-content/uploads/2024/01/check.png" width="18" height="18" alt="Check Icon"/>
			</div>
		  </div>
		  <div className="Pricing-feature-itemcontainer">
		    <h5 className="Pricing-feature-itemtext">Set Folder Access Permissions</h5>
	        <div className="Pricing-feature-textcontainer">
		    <img src="https://www.digitechsystems.com/wp-content/uploads/2024/01/check.png" width="18" height="18" alt="Check Icon"/>
			</div>
		  </div>
		  <div className="Pricing-feature-itemcontainer">
		    <h5 className="Pricing-feature-itemtext">Legendary Technical Support</h5>
	        <div className="Pricing-feature-textcontainer">
		    <img src="https://www.digitechsystems.com/wp-content/uploads/2024/01/check.png" width="18" height="18" alt="Check Icon"/>
			</div>
		  </div>
		  <div className="Pricing-feature-itemcontainer">
		    <h5 className="Pricing-feature-itemtext">Recycle Bin</h5>
	        <div className="Pricing-feature-textcontainer">
		    <img src="https://www.digitechsystems.com/wp-content/uploads/2024/01/check.png" width="18" height="18" alt="Check Icon"/>
			</div>
		  </div>
	      <div className="Pricing-feature-itemcontainer">
		    <h5 className="Pricing-feature-itemtext">Version Retention</h5>
	        <div className="Pricing-feature-textcontainer">
		    <img src="https://www.digitechsystems.com/wp-content/uploads/2024/01/check.png" width="18" height="18" alt="Check Icon"/>
			</div>
		  </div>
		  <div className="Pricing-feature-itemcontainer">
		    <h5 className="Pricing-feature-itemtext">Audit Logs</h5>
	        <div className="Pricing-feature-textcontainer">
		    <img src="https://www.digitechsystems.com/wp-content/uploads/2024/01/check.png" width="18" height="18" alt="Check Icon"/>
			</div>
		  </div>
		  <div className="Pricing-feature-itemcontainer">
		    <h5 className="Pricing-feature-itemtext">Content Extraction / Searching</h5>
	        <div className="Pricing-feature-textcontainer">
		    <img src="https://www.digitechsystems.com/wp-content/uploads/2024/01/check.png" width="18" height="18" alt="Check Icon"/>
			</div>
		  </div>
	
	      <div className="Pricing-feature-itemcontainer">
		    <h5 className="Pricing-feature-itemtext">File Retention / Destruction</h5>
	        <div className="Pricing-feature-textcontainer">
		      <img src="https://www.digitechsystems.com/wp-content/uploads/2024/01/check.png" width="18" height="18" alt="Check Icon"/>
			</div>
		  </div>
	
		  <div className="Pricing-feature-itemcontainer">
		    <h5 className="Pricing-feature-itemtext">File Sharing</h5>
	        <div className="Pricing-feature-textcontainer">
		    <img src="https://www.digitechsystems.com/wp-content/uploads/2024/01/check.png" width="18" height="18" alt="Check Icon"/>
			</div>
		  </div>
		  <div className="Pricing-feature-itemcontainer bottom-feature">
		    <h5 className="Pricing-feature-itemtext">Metadata</h5>
	        <div className="Pricing-feature-textcontainer">
		    <img src="https://www.digitechsystems.com/wp-content/uploads/2024/01/check.png" width="18" height="18" alt="Check Icon"/>
			</div>
		  </div>
		
		  </section>
			<a className="text-decoration" href="https://www.sys.tm/signup" aria-label="Try it free button">
		      <button className="welcome-button pricing-page-button">Try It Free!</button>
	 		</a>
		</section>
		
	<section className="Pricing-plans-items">
		    <h3>Standard</h3>
	
		    <div className="Pricing-plans-titles" >
				
			 <div className="Pricing-price-container">
	           <h4>$30/mo* per user</h4>
	  		   <p>when billed monthly</p>
		     </div>
			<h5>or</h5>
			 <div className="Pricing-price-container">
	           <h4><span className="Pricing-line-through">$27</span> $20.25/mo* per user</h4>
	  		   <p>when billed annually</p>
		     </div>
		
		    </div>
	
		<section className="Pricing-feature-container"> 
		  
		  <div className="Pricing-feature-itemcontainer top-feature">
		    <h5 className="Pricing-feature-itemtext">File Management</h5>
		    <div className="Pricing-feature-textcontainer">
		      <img src="https://www.digitechsystems.com/wp-content/uploads/2024/01/check.png" width="18" height="18" alt="Check Icon"/>
	         </div>
		  </div>
		  <div className="Pricing-feature-itemcontainer">
		    <h5 className="Pricing-feature-itemtext">Data Security</h5>
		    <div className="Pricing-feature-textcontainer">
		      <img src="https://www.digitechsystems.com/wp-content/uploads/2024/01/check.png" width="18" height="18" alt="Check Icon"/>
		    </div>
		  </div>
		  <div className="Pricing-feature-itemcontainer">
		    <h5 className="Pricing-feature-itemtext">100 GB Storage (per user)**</h5>
			<div className="Pricing-feature-textcontainer">
		    <img src="https://www.digitechsystems.com/wp-content/uploads/2024/01/check.png" width="18" height="18" alt="Check Icon"/>
		    </div>
		  </div>
		  <div className="Pricing-feature-itemcontainer">
		    <h5 className="Pricing-feature-itemtext">Sys.tm Windows Client</h5>
		    <div className="Pricing-feature-textcontainer">
		      <img src="https://www.digitechsystems.com/wp-content/uploads/2024/01/check.png" width="18" height="18" alt="Check Icon"/>
			</div>
		  </div>
		  <div className="Pricing-feature-itemcontainer">
		    <h5 className="Pricing-feature-itemtext">Multi Factor Authentication (MFA)</h5>
		    <div className="Pricing-feature-textcontainer">
		    <img src="https://www.digitechsystems.com/wp-content/uploads/2024/01/check.png" width="18" height="18" alt="Check Icon"/>
			</div>
		  </div>
		  <div className="Pricing-feature-itemcontainer">
		    <h5 className="Pricing-feature-itemtext">Customizable Dashboard Widgets</h5>
	        <div className="Pricing-feature-textcontainer">
		    <img src="https://www.digitechsystems.com/wp-content/uploads/2024/01/check.png" width="18" height="18" alt="Check Icon"/>
			</div>
		  </div>
		  <div className="Pricing-feature-itemcontainer">
		    <h5 className="Pricing-feature-itemtext">Set Folder Access Permissions</h5>
	        <div className="Pricing-feature-textcontainer">
		    <img src="https://www.digitechsystems.com/wp-content/uploads/2024/01/check.png" width="18" height="18" alt="Check Icon"/>
			</div>
		  </div>
		  <div className="Pricing-feature-itemcontainer">
		    <h5 className="Pricing-feature-itemtext">Legendary Technical Support</h5>
	        <div className="Pricing-feature-textcontainer">
		    <img src="https://www.digitechsystems.com/wp-content/uploads/2024/01/check.png" width="18" height="18" alt="Check Icon"/>
			</div>
		  </div>
		  <div className="Pricing-feature-itemcontainer">
		    <h5 className="Pricing-feature-itemtext">Recycle Bin</h5>
	        <div className="Pricing-feature-textcontainer">
		    <img src="https://www.digitechsystems.com/wp-content/uploads/2024/01/check.png" width="18" height="18" alt="Check Icon"/>
			</div>
		  </div>
		  <div className="Pricing-feature-itemcontainer">
		    <h5 className="Pricing-feature-itemtext nonactive">Version Retention</h5>
	        <div className="Pricing-feature-textcontainer">
		    <img src="https://www.digitechsystems.com/wp-content/uploads/2024/02/nonactive.png" width="28" height="3" alt="Check Icon"/>
			</div>
		  </div>
		  <div className="Pricing-feature-itemcontainer">
		    <h5 className="Pricing-feature-itemtext nonactive">Audit Logs</h5>
	        <div className="Pricing-feature-textcontainer">
		    <img src="https://www.digitechsystems.com/wp-content/uploads/2024/02/nonactive.png" width="28" height="3" alt="Check Icon"/>
			</div>
		  </div>
	
		  <div className="Pricing-feature-itemcontainer">
		    <h5 className="Pricing-feature-itemtext nonactive">Content Extraction / Searching</h5>
	        <div className="Pricing-feature-textcontainer">
		      <img src="https://www.digitechsystems.com/wp-content/uploads/2024/02/nonactive.png" width="28" height="3" alt="Check Icon"/>
			</div>
		  </div>
	
	      <div className="Pricing-feature-itemcontainer">
		    <h5 className="Pricing-feature-itemtext nonactive">File Retention / Destruction</h5>
	        <div className="Pricing-feature-textcontainer">
		      <img src="https://www.digitechsystems.com/wp-content/uploads/2024/02/nonactive.png" width="28" height="3" alt="Check Icon"/>
			</div>
		  </div>
	
		  <div className="Pricing-feature-itemcontainer">
		    <h5 className="Pricing-feature-itemtext nonactive">File Sharing</h5>
	        <div className="Pricing-feature-textcontainer">
		    <img src="https://www.digitechsystems.com/wp-content/uploads/2024/02/nonactive.png" width="28" height="3" alt="Check Icon"/>
			</div>
		  </div>
		  <div className="Pricing-feature-itemcontainer bottom-feature">
		    <h5 className="Pricing-feature-itemtext nonactive">Metadata</h5>
	        <div className="Pricing-feature-textcontainer">
		    <img src="https://www.digitechsystems.com/wp-content/uploads/2024/02/nonactive.png" width="28" height="3" alt="Check Icon"/>
			</div>
		  </div>
		   </section>
		    <a className="text-decoration" href="https://www.sys.tm/signup" aria-label="Sign up for free button">
		    	<button className="welcome-button pricing-page-button">Try It Free!</button>
	        </a>
		  </section>

		</section>
	
		<div className="Pricing-astrix pp-ns">
	     <p className="add-storage-p">* Pricing shown is promotional pricing and is only available for a limited time.</p>
	     <p className="add-storage-p ml-text">** Additional Storage per organization is available for purchase.</p>
        </div>	  
	
		</section>
	
<div className="pp-ns">
	
	<h2 className="Pricing-addon-title">Add-ons for Sys.tm Standard <br/><span className="style-addon-title">(included with Sys.tm Professional):</span></h2>
	
 		  <section className="Pricing-add-plans">
			 
			<div className="Pricing-plans-container">
			  <div className="Pricing-plans-subitems pp-title pp-bold-title">Audit Logs</div>
			  <div className="Pricing-plans-subitems pp-text pp-middle-text pp-bottom-border"><span className="Pricing-line-through">$6.25</span> $4.69/mo* if billed annually</div>
			  <div className="Pricing-plans-subitems pp-text">$7/mo* if billed monthly</div>
			</div>  
		    <div className="Pricing-plans-container">
			  <div className="Pricing-plans-subitems pp-title pp-bold-title">Content Extraction / Searching</div>
			  <div className="Pricing-plans-subitems pp-text pp-middle-text pp-bottom-border"><span className="Pricing-line-through">$9</span> $6.75/mo* if billed annually</div>
			  <div className="Pricing-plans-subitems pp-text">$10/mo* if billed monthly</div>
			</div>
			<div className="Pricing-plans-container ">
			  <div className="Pricing-plans-subitems pp-title pp-bold-title">File Retention / Destruction</div>
			  <div className="Pricing-plans-subitems pp-text pp-middle-text pp-bottom-border"><span className="Pricing-line-through">$6.25</span> $4.69/mo* if billed annually</div>
			  <div className="Pricing-plans-subitems pp-text">$7/mo* if billed monthly</div>
			</div>
			
	
		  </section>
	
		  <section className=" Pricing-add-plans Pricing-addplans-mobile">
		  	<div className="Pricing-plans-container pp-bottom">
			  <div className="Pricing-plans-subitems pp-title pp-bold-title">File Sharing</div> 
			  <div className="Pricing-plans-subitems pp-text pp-middle-text pp-bottom-border"><span className="Pricing-line-through">$4.50</span> $3.38/mo* if billed annually</div>
			  <div className="Pricing-plans-subitems pp-text">$5/mo* if billed monthly</div>
			</div>
			<div className="Pricing-plans-container pp-bottom">
			  <div className="Pricing-plans-subitems pp-title pp-bold-title">Metadata</div>
			  <div className="Pricing-plans-subitems pp-text pp-middle-text pp-bottom-border"><span className="Pricing-line-through">$9</span> $6.75/mo* if billed annually</div>
			  <div className="Pricing-plans-subitems pp-text">$10/mo* if billed monthly</div>
			</div>
	        <div className="Pricing-plans-container pp-bottom">
			  <div className="Pricing-plans-subitems pp-title pp-bold-title">Version Retention</div>
			  <div className="Pricing-plans-subitems pp-text pp-middle-text pp-bottom-border"><span className="Pricing-line-through">$4.50</span> $3.38/mo* if billed annually</div>
			  <div className="Pricing-plans-subitems pp-text">$5/mo* if billed monthly</div>
			</div>
		  </section>
	
	
		  <h2 className="Pricing-addon-title">Additional Storage</h2>
			<div className="Pricing-mainstorage-plans">		
 		    <section className="Pricing-storage-plans">
			 <div className="Pricing-storage-container storage-text-side">
				 <p>Every Sys.tm Professional account includes 100 GB of storage per user. If your organization's storage exceeds the included allocation, an additional cost per GB (or fraction thereof) is incurred. You will only be charged for the average storage beyond the included amount.</p>
			 </div>
			 <div className="Pricing-storage-container">
			   <div className="Pricing-plans-subitems pp-title pp-iq-title"><span className="Price-per-col">Price</span><span className="Price-per-col-gb">Per</span></div>
			   <div className="Pricing-plans-subitems pp-text pp-top-text"><span className="Price-per-col">$0.30 /mo</span><span className="Price-per-col-gb">GB</span>First additional 1-3000GB</div>
			   <div className="Pricing-plans-subitems pp-text pp-middle-text pp-bottom-border"><span className="Price-per-col">$0.20 /mo</span><span className="Price-per-col-gb">GB</span> Additional 3001-5000GB</div>
			   <div className="Pricing-plans-subitems pp-text"><span className="Price-per-col">$0.10 /mo</span><span className="Price-per-col-gb">GB</span>Additional 5001-10000GB</div>
			 </div>  
		    </section>
			  <div className="Pricing-bottom-container">
				<p>For instance, if your organization has five users and your storage utilization averages 549.3 GB, an additional $15.00 would be billed for the additional 50 GB.</p>
			  </div>
		   </div>
	
		<section className="pp-usage-container">
		  <h2 className="Pricing-addon-title1">Usage-based Features</h2>
	      <p className="Pricing-addon-p">View the information below for details about individual services, such as Sys.tm Automations and Sys.tm Flows.</p>
	    </section>
		<div className="title-icon-container">
		  <h3 className="">Sys.tm<sup>®</sup> Flows</h3>
		  <div className="p-icon-flows"></div>
		</div>
		  
			<div className="Pricing-mainstorage-plans">		
 		    <section className="Pricing-storage-plans">
			 <div className="Pricing-storage-container storage-text-side">
				 <p>Sys.tm Flows manages business processes both within and beyond your organization. It can be used to execute and monitor any business process , including insurance claim approval, accounting payments, loan processing, and employee onboarding. It can also automatically process documents added or modified within your repository.</p>
			 </div>
			 <div className="Pricing-storage-container">
				 <div className="Pricing-plans-subitems pp-title-sub pp-iq-title"><p>Flows is priced based on the number of Flow Activities executed within a month. You are only billed for what you use.</p></div>
			   <div className="Pricing-plans-subitems pp-title pp-iq-title"><span className="Price-per-col">Price</span><span className="Price-per-col-gb">Per</span></div>
			   <div className="Pricing-plans-subitems pp-text pp-top-text"><span className="Price-per-col">$0.002</span><span className="Price-per-col">Activity</span>First million activities</div>
			   <div className="Pricing-plans-subitems pp-text pp-middle-text pp-bottom-border"><span className="Price-per-col">$0.0015</span><span className="Price-per-col">Activity</span>Over 1 million activities</div>
			   <div className="Pricing-plans-subitems pp-text pp-button-text"><span className="pp-text-flows-botton">First 1,000 activites free per month</span></div>
			 </div>  
		    </section>
		   </div>
	
	
	
	    <div className="title-icon-container">
		 <h3 className="">Sys.tm<sup>®</sup> Intelligence</h3> 
		  <div className="p-icon-intel"></div>
		</div>
	
	     <div className="title-icon-container">
		 <h4 className="">AI-based Recognition</h4> 
		</div>
		  <section className="Pricing-intelligence-container">
			  
			<div className="Pricing-intelligence-plans">		
 		    <section className="Pricing-storage-plans">
			 <div className="Pricing-storage-container storage-text-side">
				 <h4>Text Recognition</h4>
				 <p>Recognize and extract English, French, Italian, German, Spanish, and Portuguese printed text and handwriting (English only) from your documents with Sys.tm's Flows and API.</p>
			 </div>
			 <div className="Pricing-storage-container">
			   <div className="Pricing-plans-subitems pp-title pp-iq-title">
				   <span className="Price-per-col">Price</span><span className="Price-per-col">Per</span></div>
			   <div className="Pricing-plans-subitems pp-text pp-top-text">
				   <span className="Price-per-col">$0.004</span><span className="Price-per-col">Page</span>First million pages</div>
			   <div className="Pricing-plans-subitems pp-text pp-middle-text pp-bottom-border"><span className="Price-per-col">$0.003</span><span className="Price-per-col">Page  </span>Over million activities</div>
			   <div className="Pricing-plans-subitems pp-text pp-button-text"><span className="pp-text-flows-botton">First 100 pages free per month</span></div>
			 </div>  
		    </section>
		   </div>
	
			<div className="Pricing-intelligence-plans Pricing-intel-middle">		
 		     <section className="Pricing-storage-plans">
			  <div className="Pricing-storage-container storage-text-side">
				 <h4>Expense Recognition</h4>
				 <p>Utilize AI to understand the context of invoices and receipts, automatically identifying the taxonomy of expense information such as vendor names, invoice or receipt date, invoice or receipt number, total amount, payment terms, and line item details (for instance, item prices).</p>
			  </div>
			  <div className="Pricing-storage-container">
			   <div className="Pricing-plans-subitems pp-title pp-iq-title"><span className="Price-per-col">Price</span><span className="Price-per-col">Per</span></div>
			   <div className="Pricing-plans-subitems pp-text pp-top-text"><span className="Price-per-col">$0.04</span><span className="Price-per-col">Page</span>First 100,000 pages</div>
			   <div className="Pricing-plans-subitems pp-text pp-middle-text pp-bottom-border"><span className="Price-per-col">$0.03</span><span className="Price-per-col">Page</span>Over 100,000 pages</div>
			   <div className="Pricing-plans-subitems pp-text pp-button-text"><span className="pp-text-flows-botton">First 100 pages free per month</span></div>
			  </div>  
		     </section>
		    </div>
	
		   <div className="Pricing-intelligence-plans">		
 		    <section className="Pricing-storage-plans">
			 <div className="Pricing-storage-container storage-text-side">
				 <h4>Identity Recognition</h4>
				 <p>Utilize AI to extract relevant information from passports, driver licenses, and other identity documentation issued by the US Government.</p>
			 </div>
			 <div className="Pricing-storage-container">
			   <div className="Pricing-plans-subitems pp-title pp-iq-title"><span className="Price-per-col">Price</span><span className="Price-per-col">Per</span></div>
			   <div className="Pricing-plans-subitems pp-text pp-top-text"><span className="Price-per-col">$0.08</span><span className="Price-per-col">Page</span>First 100,000 pages</div>
			   <div className="Pricing-plans-subitems pp-text pp-middle-text pp-bottom-border"><span className="Price-per-col">$0.06</span><span className="Price-per-col">Page</span>Over 100,000 pages</div>
			   <div className="Pricing-plans-subitems pp-text pp-button-text"><span className="pp-text-flows-botton">First 100 pages free per month</span></div>
			 </div>  
		    </section>
		   </div>

		</section>
		
	
	    <div className="title-icon-container">
		  <h4 className="">AI-Query</h4> 
		</div>

	<section className="Pricing-intelligence-container">
		
	<div className="Pricing-intelligence-plans">	
		
 		    <section className="Ai-storage-plans">
				
			  <div className="Pricing-storage-container ai-text-side2">	 
				<div className="pp1 pp1l pp-bottom no-top-border pp-border-right"> 
				   <h4>Artificial Intelligence Query</h4>
				   <p>Leverage industry-leading AI understanding models by integrating AI Query into Flows to analyze, translate, summarize, classify, and extract information from your data. Use AI Query to ask Sys.tm questions about your documents, finding critical information quickly and efficiently.</p>
				   <p>AI Query supports multiple models that accept text, images, and videos to generate textual output while balancing capability, accuracy, speed, and cost. Each supports 200+ languages, and is optimized for these 15 languages: English, German, Spanish, French, Italian, Japanese, Korean, Arabic, Simplified Chinese, Russian, Hindi, Portuguese, Dutch, Turkish, and Hebrew.</p>
				  <p>AI Query is priced by input and output tokens for each model. Tokens are the segments of text that are fed into and generated by the AI model. These can be individual characters, whole words, parts of words, or even larger chunks of text. A token generally corresponds to approximately 4 characters for common English text. For images, token consumption is dependent on the image dimensions, typically between 800 and 2600 tokens per image. You are only billed for what you use.</p>
	            </div>
				<div className="pp1 pp1r pp-bold-topborder"> 
				   <div className="Pricing-plans-subitems pp-notop-title">Nova Micro Input Tokens</div>
			       <div className="Pricing-plans-subitems pp-title pp-iq-title no-top-border"><span className="Price-per-col">Price</span><span className="Price-per-col">Per</span></div>
			   		<div className="Pricing-plans-subitems pp-text pp-top-text"><span className="Price-per-col">Free</span><span className="Price-per-col-b">Token</span><span className="Price-attended-price">First 10,000,000 tokens</span></div>
			   		<div className="Pricing-plans-subitems pp-text pp-middle-text"><span className="Price-per-col">$0.0001</span><span className="Price-per-col-b">1,000 Tokens</span><span className="Price-attended-price">10,000,001 and up</span></div>
				   
					<div className="Pricing-plans-subitems pp-top-title">Nova Micro Output Tokens</div>
			   		<div className="Pricing-plans-subitems pp-title pp-iq-title"><span className="Price-per-col">Price</span><span className="Price-per-col">Per</span></div>
			   		<div className="Pricing-plans-subitems pp-text pp-top-text"><span className="Price-per-col">Free</span><span className="Price-per-col-b">Token</span><span className="Price-attended-price">First 2,500,000 tokens</span></div>
			   		<div className="Pricing-plans-subitems pp-text pp-middle-text"><span className="Price-per-col">$0.0004</span><span className="Price-per-col-b">1,000 </span><span className="Price-attended-price">2,500,001 and up</span></div>
				   
			   		<div className="Pricing-plans-subitems pp-top-title pp-bottom-border">Nova Lite Input Tokens</div>
	
			   		<div className="Pricing-plans-subitems pp-title pp-iq-title"><span className="Price-per-col">Price</span><span className="Price-per-col">Per</span></div>
			   		<div className="Pricing-plans-subitems pp-text pp-top-textpp-bottom-border"><span className="Price-per-col">Free</span><span className="Price-per-col-b">Token</span><span className="Price-attended-price">First 5,000,000 tokens</span></div>
						
			   		<div className="Pricing-plans-subitems pp-text pp-middle-text "><span className="Price-per-col">$0.00015</span><span className="Price-per-col-b">1,000 Tokens</span><span className="Price-attended-price">5,000,001 and up</span></div>
				</div>
			 </div>
	
			<div className="Pricing-storage-container ai-text-side">	 
				<div className="pp1 pp1l pp-bottom no-top-border pp-border-right"> 
				   <h5 className="pp-ai-model ">AI Models:</h5>
				   <p><strong>• Amazon Nova Micro:</strong> A text-only model that delivers the lowest latency responses at very low cost. With a context window of 128K tokens, it excels at language understanding, translation, textual summarization, reasoning, and mathematical problem-solving.</p>
                   <p><strong>• Amazon Nova Lite:</strong> A very low cost multimodal model that is lightning fast for processing image, video, and text inputs. With a context window of 300K tokens, it excels at real-time interactions, document analysis, and visual question answering. It can read, analyze, classify, summarize, and answer questions about textual content (pdf, csv, doc, docx, xls, xlsx, html, txt, and md files), video (mp4, mov, mkv, webm, flv, mpeg, mpg, wmv, and 3gp), and image (png, jpg, jpeg, gif, and webp) files.</p>
				   <p><strong>• Amazon Nova Pro:</strong> A highly capable multimodal model with the best combination of accuracy, speed, and cost for a wide range of tasks. It provides enhanced accuracy and capabilities over the Nova Lite model.</p>
	            </div>
				<div className="pp1 pp1r pp-bold-topborder"> 
				   <div className="Pricing-plans-subitems pp-top-title no-top-border">Nova Lite Output Tokens</div>
			   <div className="Pricing-plans-subitems pp-title pp-iq-title"><span className="Price-per-col">Price</span><span className="Price-per-col">Per</span></div>
			   <div className="Pricing-plans-subitems pp-text pp-top-text"><span className="Price-per-col">Free</span><span className="Price-per-col-b">Token</span><span className="Price-attended-price">First 1,000,000 tokens</span></div>
			   <div className="Pricing-plans-subitems pp-text pp-middle-text"><span className="Price-per-col">$0.0007</span><span className="Price-per-col-b">1,000 Tokens</span><span className="Price-attended-price">1,000,001 and up</span></div>  
				   
			   <div className="Pricing-plans-subitems pp-top-title">Nova Pro Input Tokens</div>
			   <div className="Pricing-plans-subitems pp-title pp-iq-title"><span className="Price-per-col">Price</span><span className="Price-per-col">Per</span></div>
			   <div className="Pricing-plans-subitems pp-text pp-top-text"><span className="Price-per-col">Free</span><span className="Price-per-col-b">Token</span><span className="Price-attended-price">First 500,000 tokens</span></div>
			   <div className="Pricing-plans-subitems pp-text pp-middle-text"><span className="Price-per-col">$0.002</span><span className="Price-per-col-b">1,000 Tokens</span><span className="Price-attended-price">500,001 and up</span></div>
	
				<div className="Pricing-plans-subitems pp-top-title">Nova Pro Output Tokens</div>
			   <div className="Pricing-plans-subitems pp-title pp-iq-title"><span className="Price-per-col">Price</span><span className="Price-per-col">Per</span></div>
			   <div className="Pricing-plans-subitems pp-text pp-top-text"><span className="Price-per-col">Free</span><span className="Price-per-col-b">Token</span><span className="Price-attended-price">First 100,000 tokens</span></div>
			   <div className="Pricing-plans-subitems pp-text pp-middle-text"><span className="Price-per-col">$0.01</span><span className="Price-per-col-b">1,000 Tokens</span><span className="Price-attended-price">100,001 and up</span></div> 
				</div>
	
			 </div>
	
	
		    </section>
	
		   </div>
	
	      </section>
	
	
	    <div className="title-icon-container">
		 <h3>Sys.tm<sup>®</sup> Automations</h3>
		</div>

	
			<div className="Pricing-mainstorage-plans">	
				
 		     <section className="Pricing-storage-plans">
				  
			   <div className="Pricing-storage-container storage-text-side">
				 
				 <p>Automations uses robotic process automation (RPA) technology to perform the back-office tasks of human workers, such as extracting data, filling in forms, or transferring files. Automations offers two different types of automation agents: interactive and unattended.</p>
			   </div>
	
			   <div className="Pricing-storage-container">
			     <div className="Pricing-plans-subitems pp-title pp-iq-title">Automations is priced based on the number of clock hours (or fraction thereof). You are only billed for what you use.</div>
			     <div className="Pricing-plans-subitems pp-text pp-top-text"><span className="Price-agent-automations">Attended Agent Automations</span><span className="Price-attended-price">$0.15/hour/agent</span></div>
			     <div className="Pricing-plans-subitems pp-text pp-middle-text"><span className="Price-agent-automations">Unattended Agent Automations</span><span className="Price-attended-price">$0.40/hour/agent</span></div>
			   </div> 
	
	          
	
			  </section>
	
	          <div className="Pricing-bottom-container"> 
				 <p>Interactive Automations - Allow users to integrate multiple applications together and initiate a series of intelligent, complex (or simple) steps to be taken. </p>
				 <p>Unattended Automations - Can perform a series of steps without any human intervention to perform repetitive tasks.</p>
			   </div>
	
			</div>
	
	
			<h2 className="Pricing-addon-title">Additional Services</h2>
	
		<section className="Pricing-mainstorage-plans">
			
			<div className="Pricing-subcontainer-plans">		
 		      <section className="Pricing-storage-plans">
			   <div className="Pricing-storage-container storage-text-side">
				 <h4>PDF Generation</h4>
				 <p>Generate PDF documents (including full-text searchable PDFs when using recognition data) from your JPEG, PNG, PDF, and TIFF images with Sys.tm's Flows and API.</p>
			   </div>
			   <div className="Pricing-storage-container">
			     <div className="Pricing-plans-subitems pp-title pp-iq-title"><span className="Price-per-col">Price</span><span className="Price-per-col">Per</span></div>
			     <div className="Pricing-plans-subitems pp-text pp-top-text"><span className="Price-per-col">$0.002</span><span className="Price-per-col">Page</span><span className="Price-per-disclaimer">First 100,000 pages</span></div>
			     <div className="Pricing-plans-subitems pp-text pp-middle-text pp-bottom-border">
					 <span className="Price-per-col">
						 $0.0015</span><span className="Price-per-col">Page</span><span className="Price-per-disclaimer">Over 100,000 pages</span></div>
				 <div className="Pricing-plans-subitems pp-text pp-button-text"><span className="pp-text-flows-botton">First 100 pages free per month</span></div>
			   </div>  
			  </section>
			</div>
	
			<div className="Pricing-subcontainer-plans pp-top-bottom">		
 		      <section className="Pricing-storage-plans">
			   <div className="Pricing-storage-container storage-text-side">
				 <h4>API Integration</h4>
				 <p>Integrate Sys.tm functionality into your line of business applications utilizing Sys.tm's extensive API.</p>
			   </div>
			   <div className="Pricing-storage-container">
			     <div className="Pricing-plans-subitems pp-title pp-iq-title"><span className="Price-per-col">Price</span><span className="Price-per-col">Per</span></div>
			     <div className="Pricing-plans-subitems pp-text pp-top-text pp-bottom-border"><span className="Price-per-col">$0.0005</span><span className="Price-per-col">Call</span></div>
				 <div className="Pricing-plans-subitems pp-text pp-button-text"><span className="pp-text-flows-botton">First 100,000 calls free per month</span></div>
			   </div>  
			  </section>
			</div>
	
			<div className="Pricing-subcontainer-plans">		
 		      <section className="Pricing-storage-plans">
			   <div className="Pricing-storage-container storage-text-side">
				 <h4>File Icons</h4>
				 <p>As files are stored in Sys.tm, file icons/images of the first page of each supported file can be automatically generated and stored to allow users to navigate file listings and search results while displaying the icons instead of file details.</p>
			   </div>
			   <div className="Pricing-storage-container">
			     <div className="Pricing-plans-subitems pp-title pp-iq-title"><span className="Price-per-col">Price</span><span className="Price-per-col">Per</span></div>
			     <div className="Pricing-plans-subitems pp-text pp-top-text pp-bottom-border"><span className="Price-per-col">$0.0005</span><span className="Price-per-col">Icon</span><span className="Price-per-disclaimer">First 1,000,000 pages</span></div>
				 <div className="Pricing-plans-subitems pp-text pp-bottom-border"><span className="Price-per-col">$0.0004</span><span className="Price-per-col">Icon</span>  <span className="Price-per-disclaimer">First 1,000,001 and up</span></div>
			   </div>  
			  </section>
			</div>
	
		</section>
	</div>
			 
		<section className="Pricing-button-container pp-ns">
		  <div className="Pricing-button-subcontainer">
		    <h5 className="Pricing-button-h5">Explore the different specifications offered by Sys.tm on the pricing sheet by downloading it below.</h5>
		  </div>	
		  <div className="Pricing-button-subcontainer pricing-sheet-button">
		     <a href="https://www.digitechsystems.com/wp-content/uploads/systm/Systm Promotion Pricing Sheet.pdf" className="welcome-button pricing-button t-dec">Sys.tm Pricing Sheet</a>
		  </div>		  
		</section>
	
				
	      <section className="Pricing-RPA-container">
		    <div className="Pricing-RPA-left">
			  <h2>Sys.tm Automations</h2>
	   	      <p>Robotic process automation (RPA)<br />Automate back-office tasks</p>
			</div>
		    <div className="Pricing-RPA-right">
				<h3 className="Pricing-RPA-righttext">RPA prices based on number of clock hours services are run. <br />You are only billed for what you use:</h3>
				
				<div className="Pricing-RPA-rightitems">
				  <p>Interactive Agent Automations</p>
				  <p>$0.15/hour/agent</p>
				</div>
				<div className="Pricing-RPA-rightitems bottom-rpa">
				  <p>Unattended Agent Automations</p>
				  <p>$0.40/hour/agent</p>
				</div>
			</div>
	      </section>
	
		  <section className="Pricing-doc-capture">
		    <p>Intelligent Document Capture Integration: Sys.tm includes prebuilt integration for upload <br />from <a href="https://www.digitechsystems.com/products/papervision-capture/" className="n-link-dec">PaperVision<sup>®</sup> Capture,</a> <a href="https://www.digitechsystems.com/products/papervision-forms-magic-technology/" className="n-link-dec">PaperVision<sup>®</sup> Forms Magic<sup>™</sup>,</a> and <a href="https://www.digitechsystems.com/products/paperflow/" className="n-link-dec">PaperFlow<sup>™</sup>.</a></p>
		  </section>

		 	 <section className="Home-contact-container Pricing-contact-container">
		     <div className="Home-contact-items contact-container-left">
		
		  </div>
	
	
	
	      <div className="Home-contact-items contact-container-right">
					
			<div className="contact-left-textblock">
		    	<h2>How Can We Help?</h2>
				<p>Sys.tm's advanced solutions are more efficient and save energy to do other things.</p>
			</div>
	
			<div className="Home-contact-iconcontainer">
		
				<div className="Home-contact-subcontainer fast-call">
		        	<div className="Home-contact-fast-call">
						<img className="welcome-bigpicture-img" src="https://www.digitechsystems.com/wp-content/uploads/2024/07/fast-call-wr.png" width="37" height="37" alt="Fast call icon" /> 
		            </div>
					<h5>We’ll give you a call back within 1 business day.</h5>
				  </div>
															
				  <div className="Home-contact-subcontainer demo-call">
		        	<div className="Home-contact-fast-call">
						<img className="welcome-bigpicture-img bpi-bottom" src="https://www.digitechsystems.com/wp-content/uploads/2024/07/live-demo-wr.png" width="37" height="37" alt="demo icon" /> 
		            </div>
					<h5>Explore the platform live with our specialists.</h5>
				  </div>
	
	            <NavLink to="/contact" className="welcome-button text-decortion pricing-contact-button">Contact</NavLink>
		    </div>
					

		  </div>
	
		</section>
				
		<Footer />
	
	  </section>
	)
}

export default Pricing;