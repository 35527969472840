import React, { useEffect } from "react";
import { NavLink } from 'react-router-dom';
import Footer from '../Footer/Footer.js';

import '../styles/HowMightSystmHelpYou.css';
import '../Pricing/Pricing.css';

function Example() {
  useEffect(() => {
    document.title = 'How might Sys.tm help you';
  }, []);
}


const HowMightSystmHelpYou = () => {
	
//	componentDidMount() {
//    document.title = "How might Sys.tm help you";
//	document.description = "No matter your business challenge, Sys.tm’s flexible architecture and rich feature set can help";	
//  }
//	
	return(
	  <section className="HowMightSystmHelpYou-container">
		
		<div className="Navbar-promo-subcontainer">
		<a href="https://www.sys.tm/signup" className="sign-in-nav" id="signup-button" aria-label="Sign up now button"><p>Limited Time: FREE for 30 Days. Sign Up Now</p></a>
	    </div>
		
		<div className="Navbar-2025promo-subcontainer">
		  <a href="https://www.sys.tm/signup" className="sign-in-nav" id="signup-button" aria-label="Sign up button"><p>Save an Additional 25% on Annual Subscriptions. Offer Expires June 30, 2025.</p></a>
	    </div>
		
		<div className="Navbar-epson-subcontainer">
		  <a href="https://www.scw.com/digitech/" className="promo-sign-in" id="signup-button" aria-label="Sign up button"><p>LIMITED TIME OFFER: Exclusive Epson Document Management Bundle</p></a>
	    </div>
		
		<div className="HowMightSystmHelpYou-Headerbanner-container">
		
		  <div className="HowMightSystmHelpYou-Headerbanner-textcontainer">
		    <h1>How Might Sys.tm Help You?</h1>
			<p>No matter your business challenge, Sys.tm’s flexible architecture and rich feature set can help!</p>
				
			<div class="welcome-button-container" i18n>

        	  <a
          		mat-stroked-button
          		href="https://www.digitechsystems.com/wp-content/uploads/2024/09/How-Will-Systm-Microservices-Change-Your-Business.pdf"
          		class="welcome-button mdc-button mdc-button--outlined mat-mdc-outlined-button mat-unthemed mat-mdc-button-base cdk-focused cdk-mouse-focused"
          		aria-label="Start now button">Learn More
		      </a>
		
      		</div>
		
		  </div>
		
		</div>
		
		<NavLink to="/genai-announcement" className="Learn-press-release" rel="noopener noreferrer">
		  <h3>Introducing Sys.tm: The perfect balance of intelligent information management, automation, AI, value and functionality.</h3>
		</NavLink>
		
		<section className="How-bigpicture-container">
		
		  <div className="How-bigpicture-intro-hidden">
		      <h3 className="HowMightSystmHelpYou-h3">We think Sys.tm is great, but we don’t expect you to believe us.</h3>
		      <p className="HowMightSystmHelpYou-p">Below, we’ll add stories from Sys.tm customers, analysts and product reviewers, and even the media. Since Sys.tm is new, we’ve started the list with a couple of scenarios that you might find familiar and explained how Sys.tm can help address these common business challenges. Enjoy!</p>
		    </div>
		
		  <div className="How-bigpicture-itemcontainer">
		
		    <div className="How-bigpicture-intro">
		      <h3 className="HowMightSystmHelpYou-h3">We think Sys.tm is great, but we don’t expect you to believe us.</h3>
		      <p className="HowMightSystmHelpYou-p">Below, we’ll add stories from Sys.tm customers, analysts and product reviewers, and even the media. Since Sys.tm is new, we’ve started the list with a couple of scenarios that you might find familiar and explained how Sys.tm can help address these common business challenges. Enjoy!</p>
		    </div>
		
		
		<div className="How-items-container">
		
		<section className="How-3pack-subcontainer">
		
		  	   <div className="How-bigpicture-items How-bigpicture-a">
		
				<div className="How-bigpicture-imgblock How-imgblock-a"></div>
				  <div className="How-bigpicture-text" >	
		        	<h3>Need to Recognize Faster ROI from Tech?</h3>
					<p>When your business cycles down, the pressure to reduce costs mounts, and technology can be a great way to streamline processes and maximize effectiveness. We all know, however, that some technologies take longer than others to pay back their costs over time. In fact, as much as 80% of the features in the average software product are rarely or never used.<sup className="HowMightSystmHelpYou-sup">1</sup>  We’ve got your back! Sys.tm’s innovative architecture allows you to customize available features and pay only for what you use.</p>
				  </div>
	
		        </div>
		
		
				<div className="How-bigpicture-items How-bigpicture-b1">
				
				  <div className="How-bigpicture-imgblock How-imgblock-b1"></div>
				    <div className="How-bigpicture-text" >
					  <h3>Interested in AI?</h3>
					  <p>More than 80% of companies have tried Artificial Intelligence (AI), but only a handful have found ways to turn it into real business benefits.<sup className="HowMightSystmHelpYou-sup">2</sup> Sys.tm<sup>®</sup> Intelligence is different. Not only is it easier to understand and use, but you can embed AI tasks into any process flow you have. It’s the smartest way to leverage AI into real growth for your organization.</p>
				    </div>
		
			      </div>
		
		
			    <div className="How-bigpicture-items How-bigpicture-b">
				  <div className="How-bigpicture-imgblock How-imgblock-b"></div>
				    <div className="How-bigpicture-text" >
					  <h3>Concerned About Cyber Security?</h3>
					  <p>Ouch! Cyber attacks are on the rise…both in number and cost.<sup className="HowMightSystmHelpYou-sup">3</sup> You can’t afford to take it lightly or to assume your business isn’t a target. Sys.tm’s simple-to-setup security capabilities provide big protection for your most sensitive information. You’ll rest easy knowing access controls, encryption, multi-factor authentication, and more protect every document in Sys.tm.</p>
				    </div>
			      </div>
					  
			</section>		  
		
			<section className="How-3pack-subcontainer">
						  
			<div className="How-bigpicture-items How-bigpicture-c">
				<div className="How-bigpicture-imgblock How-imgblock-c"></div>
				<div className="How-bigpicture-text" >
		          <h3>Anxious to Automate?</h3>
				  <p>The 2020s have been called the decade of hyper-automation,<sup className="HowMightSystmHelpYou-sup">4</sup> because so many businesses are turning to Artificial Intelligence (AI) based automation and Robotic Process Automation (RPA) to streamline costs. You’ll love both the easy setup and sensible, usage-based pricing only Sys.tm can offer when it comes to automation. Whether it’s a repeated task or a need to recognize and route documents and information, it’s never been easier to automate!</p>
		        </div>
		
		    </div>
		
			<div className="How-bigpicture-items How-bigpicture-d">
				<div className="How-bigpicture-imgblock How-imgblock-d"></div>
			    <div className="How-bigpicture-text" >
					<h3>Losing Locations for Critical Documents?</h3>
			        <p>Does your desktop look like your computer threw up? If you’re managing files by putting them all in view or using system search capabilities to find what you need, you’re losing critical time to poor information management. Starmind estimates the average worker loses more than 100 minutes daily to searching for documents.<sup className="HowMightSystmHelpYou-sup">5</sup>  Not only will Sys.tm automatically ingest all your files, it’ll setup sensible organization and create file metadata, enabling you to find anything as soon as you need it.</p>
				</div>
			</div>  
				  
			<div className="How-bigpicture-items How-bigpicture-e">
				<div className="How-bigpicture-imgblock How-imgblock-e"></div>
				<div className="How-bigpicture-text" >
					<h3>Plagued By Paper Files?</h3>
					<p>Listen…most businesses still have some paper.<sup className="HowMightSystmHelpYou-sup">6</sup>  Whether it’s older student files, financial records, or patient charts that must be maintained in order to comply with regulations or just a backlog of lesser-used documents you haven’t found time to sort through, paper can be costly to maintain and secure. Sys.tm works with both PaperVision<sup>®</sup> Capture and PaperFlow<sup>™</sup> to ingest scanned files, and data can then be extracted from the documents automatically and sent to other business applications.</p>
				</div>
		
		    </div>
					  
			</section>	
				<div className="HowMightSystmHelpYou-gains-innercontainer HowMightSystmHelpYou-gains-footnotes">
				  <ul>
					<li><a href="https://go.pendo.io/rs/185-LQW-370/images/2019%20Feature%20Adoption%20Report20Digital.pdf" aria-label="1. Resource link">1) go.pendo.io/rs/185-LQW-370/images/2019%20Feature%20Adoption%20Report20Digital.pdf/</a></li>
		
  					<li><a href="https://www.mckinsey.com/~/media/mckinsey/business%20functions/quantumblack/our%20insights/the%20state%20of%20ai/2025/the-state-of-ai-how-organizations-are-rewiring-to-capture-value_final.pdf?shouldindex=false" aria-label="2. Resource link">2) www.mckinsey.com/~/media/mckinsey/business%20functions/quantumblack/our%20insights/the%20state%20of%20ai/2025/the-state-of-ai-how-organizations-are-rewiring-to-capture-value_final.pdf?shouldindex=false</a></li>
						
  					<li><a href="https://www.ibm.com/downloads/documents/us-en/10a99803ab2fd7ac" aria-label="3. Resource link">3) www.ibm.com/downloads/cas/3R8N1DZJ</a></li>
						
  					<li><a href="https://www.gartner.com/smarterwithgartner/gartner-top-10-strategic-technology-trends-for-2020" aria-label="4. Resource link">4) www.gartner.com/smarterwithgartner/gartner-top-10-strategic-technology-trends-for-2020</a></li>
						  
					<li><a href="https://www.starmind.ai/hubfs/Assets%202022/Future%20of%20Work%20Report%20-%20The%20High%20Cost%20of%20Inaccessible%20Knowledge/Future%20of%20work_Research%20report.pdf">5) www.starmind.ai/hubfs/Assets%202022/Future%20of%20Work%20Report%20-%20The%20High%20Cost%20of%20Inaccessible%20Knowledge/Future%20of%20work_Research%20report.pdf
					</a></li>
						  
				     <li><a href="https://www.vistage.com/research-center/business-financials/economic-trends/20211209-trends-facing-business-in-2022-and-beyond" aria-label="6. Resource link">6) www.vistage.com/research-center/business-financials/economic-trends/20211209-trends-facing-business-in-2022-and-beyond</a></li>
						
				  </ul>
				</div>	  
		
		</div>
				  
				  
		  </div>
		
		        
		
		</section>
			
			<section className="HowMightSystmHelpYou-salecontact-container">
				<div className="HowMightSystmHelpYou-salecontact-leftcontainer salecontact-subcontainer">
					<h2>Interested in speaking with us about how Sys.tm could help you?<br />Visit our Contact and About Us page.</h2>
			 	</div>
				<div className="HowMightSystmHelpYou-salecontact-rightcontainer salecontact-subcontainer">
						<NavLink className="welcome-button contact-button" to="/contact">Contact</NavLink>
				</div>
			</section>
			
			
					  <section className="Home-products-container">
		
		    <div className="Home-products-item Products-container-left">
		 
		     <div className="Home-leftbackground-container">
		
		      <div className="Home-products-lefttext">
		        <h3>Discover the Features that Make Sys.tm so Easy to Use</h3>
				<p>Download our Product Sheet and Pricing Guide to better understand the Sys.tm experience.</p>
	          </div>  
		
	            <div className="Home-products-buttonbox">
		
		      	  <div className="Home-products-buttoncontainer">
				    <a className="text-decoration" href="http://www.info.sys.tm.s3.amazonaws.com/uploads/Systm Product Sheet.pdf" target="_blank" rel="noreferrer" aria-label="Product sheet"><button className="welcome-button">Product Sheet</button></a>
		          </div>
	
			      <div className="Home-products-buttoncontainer">
				    <a className="text-decoration" href="https://www.digitechsystems.com/wp-content/uploads/systm/Systm Promotion Pricing Sheet.pdf" target="_blank" rel="noreferrer" aria-label="Pricing sheet"><button className="welcome-button">Pricing Sheet</button></a>
			      </div>
	
			    </div>
	
			 </div>
	
		    </div>
	
			<div className="Home-products-item Products-container-right">
					<img className="products-sheet-img" src="https://s3.us-east-1.amazonaws.com/www.info.sys.tm/uploads/features-computer.png" width="" height="" alt="Computer Icon" />
		    </div>
		  </section>
			
			
		  <div className="HowMightSystmHelpYou-pricing-container">
						
			  <div className="HowMightSystmHelpYou-pricing-itemcontainer">
						
						
				<div className="HowMightSystmHelpYou-pricing-items">
				  <h2>Find the plan that works best with you</h2>
				  <p>Here’s a preview of Sys.tm’s innovative purchasing options. Visit the Pricing page to learn more.</p>
				  <NavLink className="welcome-button how-pricing-button" to="/pricing">Choose Plan</NavLink>
				</div>
						
					
				<div className="HowMightSystmHelpYou-pricing-items pricing-options">
				
				   <section className="HowMightSystmHelpYou-plans-items">
						<h4>PROFESSINOAL</h4>
		    	     <div className="How-plans-titles" >
			           <div className="Pricing-price-container">
	                     <h4>$55/mo* per user</h4>
	  		             <p>when billed monthly</p>
		               </div>
			          <h5>or</h5>
			         <div className="Pricing-price-container">
	                    <h4><span className="Pricing-line-through">$49.50</span> $37.13/mo* per user</h4>
	  		            <p>when billed annually</p>
		             </div>
		           </div> 
		      
				    <section className="HowMightSystmHelpYou-feature-container">
		  
		  		   <div className="HowMightSystmHelpYou-feature-itemcontainer HowMightSystmHelpYou-top-feature">
		    <h5 className="HowMightSystmHelpYou-feature-itemtext">File Management</h5>
		    <div className="HowMightSystmHelpYou-feature-textcontainer">
		      <img src="https://www.digitechsystems.com/wp-content/uploads/2024/01/check.png" width="18" height="18" alt="Check icon"/>
	         </div>
		  </div>
		  <div className="HowMightSystmHelpYou-feature-itemcontainer">
		    <h5 className="HowMightSystmHelpYou-feature-itemtext">Data Security</h5>
		    <div className="HowMightSystmHelpYou-feature-textcontainer">
		      <img src="https://www.digitechsystems.com/wp-content/uploads/2024/01/check.png" width="18" height="18" alt="Computer Icon"/>
		    </div>
		  </div>
		  <div className="HowMightSystmHelpYou-feature-itemcontainer">
		    <h5 className="HowMightSystmHelpYou-feature-itemtext">Set Folder Access Permissions</h5>
	        <div className="HowMightSystmHelpYou-feature-textcontainer">
		    <img src="https://www.digitechsystems.com/wp-content/uploads/2024/01/check.png" width="18" height="18" alt="Computer Icon"/>
			</div>
		  </div>
		  <div className="HowMightSystmHelpYou-feature-itemcontainer">
		    <h5 className="HowMightSystmHelpYou-feature-itemtext">Legendary Technical Support</h5>
	        <div className="HowMightSystmHelpYou-feature-textcontainer">
		    <img src="https://www.digitechsystems.com/wp-content/uploads/2024/01/check.png" width="18" height="18" alt="Computer Icon"/>
			</div>
		  </div>
		  <div className="HowMightSystmHelpYou-feature-itemcontainer">
		    <h5 className="HowMightSystmHelpYou-feature-itemtext nonactive">Version Retention</h5>
	        <div className="HowMightSystmHelpYou-feature-textcontainer">
		    <img src="https://www.digitechsystems.com/wp-content/uploads/2024/01/check.png" width="18" height="18" alt="Computer Icon"/>
			</div>
		  </div>
		  <div className="HowMightSystmHelpYou-feature-itemcontainer">
		    <h5 className="HowMightSystmHelpYou-feature-itemtext nonactive">Audit Logs</h5>
	        <div className="HowMightSystmHelpYou-feature-textcontainer">
		    <img src="https://www.digitechsystems.com/wp-content/uploads/2024/01/check.png" width="18" height="18" alt="Computer Icon"/>
			</div>
		  </div>
		  <div className="HowMightSystmHelpYou-feature-itemcontainer">
		    <h5 className="HowMightSystmHelpYou-feature-itemtext nonactive">Content Extraction / Searching</h5>
	        <div className="HowMightSystmHelpYou-feature-textcontainer">
		    <img src="https://www.digitechsystems.com/wp-content/uploads/2024/01/check.png" width="18" height="18" alt="Computer Icon"/>
			</div>
		  </div>
		  <div className="HowMightSystmHelpYou-feature-itemcontainer">
		    <h5 className="HowMightSystmHelpYou-feature-itemtext nonactive">File Retention / Destruction</h5>
	        <div className="HowMightSystmHelpYou-feature-textcontainer">
		      <img src="https://www.digitechsystems.com/wp-content/uploads/2024/01/check.png" width="18" height="18" alt="Check Icon"/>
			</div>
		  </div>
		  <div className="HowMightSystmHelpYou-feature-itemcontainer">
		    <h5 className="HowMightSystmHelpYou-feature-itemtext nonactive">File Sharing</h5>
	        <div className="HowMightSystmHelpYou-feature-textcontainer">
		    <img src="https://www.digitechsystems.com/wp-content/uploads/2024/01/check.png" width="18" height="18" alt="Computer Icon"/>
			</div>
		  </div>
		  <div className="HowMightSystmHelpYou-feature-itemcontainer bottom-feature">
		    <h5 className="HowMightSystmHelpYou-feature-itemtext nonactive">Metadata</h5>
	        <div className="HowMightSystmHelpYou-feature-textcontainer">
		    <img src="https://www.digitechsystems.com/wp-content/uploads/2024/01/check.png" width="18" height="18" alt="Computer Icon"/>
			</div>
		  </div>
		
		  </section>
					
		  </section>
					
				</div>
					
					
					
					<section className="HowMightSystmHelpYou-pricing-items pricing-options">
				
				  	<div className="HowMightSystmHelpYou-plans-items ">
		    		  <h4>STANDARD</h4>
		    			<div className="How-plans-titles" >
				
			 <div className="Pricing-price-container">
	           <h4>$30/mo* per user</h4>
	  		   <p>when billed monthly</p>
		     </div>
			<h5>or</h5>
			 <div className="Pricing-price-container">
	           <h4><span className="Pricing-line-through">$27</span> $20.25/mo* per user</h4>
	  		   <p>when billed annually</p>
		     </div>
		
		    </div>
		
					<section className="HowMightSystmHelpYou-feature-container">
		  
		  			  <div className="HowMightSystmHelpYou-feature-itemcontainer HowMightSystmHelpYou-top-feature">
		    			<h5 className="HowMightSystmHelpYou-feature-itemtext">File Management</h5>
		    			<div className="HowMightSystmHelpYou-feature-textcontainer">
		      			<img src="https://www.digitechsystems.com/wp-content/uploads/2024/01/check.png" width="18" height="18" alt="Computer Icon"/>
	         		  </div>
		  			</div>
		
		  <div className="HowMightSystmHelpYou-feature-itemcontainer">
		    <h5 className="HowMightSystmHelpYou-feature-itemtext">Data Security</h5>
		    <div className="HowMightSystmHelpYou-feature-textcontainer">
		      <img src="https://www.digitechsystems.com/wp-content/uploads/2024/01/check.png" width="18" height="18" alt="Computer Icon"/>
		    </div>
		  </div>
		
		  <div className="HowMightSystmHelpYou-feature-itemcontainer">
		    <h5 className="HowMightSystmHelpYou-feature-itemtext">Set Folder Access Permissions</h5>
	        <div className="HowMightSystmHelpYou-feature-textcontainer">
		    <img src="https://www.digitechsystems.com/wp-content/uploads/2024/01/check.png" width="18" height="18" alt="Computer Icon"/>
			</div>
		  </div>
		  
		  <div className="HowMightSystmHelpYou-feature-itemcontainer">
		    <h5 className="HowMightSystmHelpYou-feature-itemtext">Legendary Technical Support</h5>
	        <div className="HowMightSystmHelpYou-feature-textcontainer">
		    <img src="https://www.digitechsystems.com/wp-content/uploads/2024/01/check.png" width="18" height="18" alt="Computer Icon"/>
			</div>
		  </div>
	
		  <div className="HowMightSystmHelpYou-feature-itemcontainer">
		    <h5 className="HowMightSystmHelpYou-feature-itemtext nonactive nonactive-h5">Version Retention</h5>
	        <div className="HowMightSystmHelpYou-feature-textcontainer">
		    <img src="https://www.digitechsystems.com/wp-content/uploads/2024/02/nonactive.png" width="28" height="3" alt="Computer Icon"/>
			</div>
		  </div>
	
		  <div className="HowMightSystmHelpYou-feature-itemcontainer">
		    <h5 className="HowMightSystmHelpYou-feature-itemtext nonactive nonactive-h5">Audit Logs</h5>
	        <div className="HowMightSystmHelpYou-feature-textcontainer">
		    <img src="https://www.digitechsystems.com/wp-content/uploads/2024/02/nonactive.png" width="28" height="3" alt="Computer Icon"/>
			</div>
		  </div>
	
		  <div className="HowMightSystmHelpYou-feature-itemcontainer">
		    <h5 className="HowMightSystmHelpYou-feature-itemtext nonactive nonactive-h5">Content Extraction / Searching</h5>
	        <div className="HowMightSystmHelpYou-feature-textcontainer">
		    <img src="https://www.digitechsystems.com/wp-content/uploads/2024/02/nonactive.png" width="28" height="3" alt="Computer Icon"/>
			</div>
		  </div>
	
		  <div className="HowMightSystmHelpYou-feature-itemcontainer">
		    <h5 className="HowMightSystmHelpYou-feature-itemtext nonactive nonactive-h5">File Retention / Destruction</h5>
	        <div className="HowMightSystmHelpYou-feature-textcontainer">
		      <img src="https://www.digitechsystems.com/wp-content/uploads/2024/02/nonactive.png" width="28" height="3" alt="Computer Icon"/>
			</div>
		  </div>
					
		  <div className="HowMightSystmHelpYou-feature-itemcontainer">
		    <h5 className="HowMightSystmHelpYou-feature-itemtext nonactive nonactive-h5">File Sharing</h5>
	        <div className="HowMightSystmHelpYou-feature-textcontainer">
		    <img src="https://www.digitechsystems.com/wp-content/uploads/2024/02/nonactive.png" width="28" height="3" alt="Computer Icon"/>
			</div>
		  </div>
	
		  <div className="HowMightSystmHelpYou-feature-itemcontainer bottom-feature">
		    <h5 className="HowMightSystmHelpYou-feature-itemtext nonactive nonactive-h5">Metadata</h5>
	        <div className="HowMightSystmHelpYou-feature-textcontainer"> 
		    <img src="https://www.digitechsystems.com/wp-content/uploads/2024/02/nonactive.png" width="28" height="3" alt="Computer Icon"/>
			</div>
		  </div>
		
		
		</section>
			
	  </div>
	</section>
					
		      </div>
					
		  </div>
					
		 <Footer />			
					
	  </section>
	
	)
}

export default HowMightSystmHelpYou;